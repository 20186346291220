import React from 'react';
import { ListGroup, Button } from 'react-bootstrap';

class Home extends React.Component {

    constructor() {
        super()
        this.state = {
            'scribbles': [
                {'title':'Design for Spaceship', 'date':'10-3-19', 'href': 'bla'}
            ]
        }
    }

    createScribbleEntry = (scribbleData) => {
        return (
            <ListGroup.Item action href={"/s/" + scribbleData.href} key={scribbleData.title}>
                <div className="d-flex justify-content-between">
                <span>{scribbleData.title}</span>
                <span>{scribbleData.date}</span>
            </div>
            </ListGroup.Item>
        )
    };

    render = () => {

        var scribblesButtons;
        if (this.state['scribbles'].length == 0) {
            scribblesButtons = <Button size="lg">Start your first scribble now :)</Button>;
        } else {
            var scribbleEntries = [];
            for (var i = 0; i < this.state['scribbles'].length; i++) {
                scribbleEntries.push(this.createScribbleEntry(this.state.scribbles[i]));
            }
            scribblesButtons = (
                <ListGroup>
                    {scribbleEntries}
                </ListGroup>
            );
        }


        return (
            <div>
                <h2 className="m-5">Go forth and scribble</h2>
                {scribblesButtons}
            </div>
        );
        }
}

export default Home;