import React from 'react';
import { Button } from 'react-bootstrap'
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/auth";

import Home from './Home.js';
import About from './About.js';
import Scribble from './Scribble.js';
import Login from './Login.js';
import Account from './Account.js';
import { render } from '@testing-library/react';

const firebaseConfig = {
  apiKey: "AIzaSyAF9TC6jMEUVIMJUXpiPV6Q-X0E_76s9oA",
  authDomain: "lunascribble.firebaseapp.com",
  databaseURL: "https://lunascribble.firebaseio.com",
  projectId: "lunascribble",
  storageBucket: "lunascribble.appspot.com",
  messagingSenderId: "883404756171",
  appId: "1:883404756171:web:2128499061f1dd6b20374e",
  measurementId: "G-W5243GDQK3"
};

class App extends React.Component {

  constructor() {
    super()

    this.state = {
      'user': {
        'displayName':'',
        'email':'',
        'emailVerified':'',
        'photoURL':'',
        'isAnonymous':'',
        'uid':'',
        'providerData':''
      }
    }

    this.setUser = this.setUser.bind(this);

  }

  componentDidMount() {

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    firebase.auth().onAuthStateChanged(this.setUser);

  }

  setUser(user) {
    if (user) {
      // User is signed in.
      this.setState(
        {
          'user': {
            'displayName': user.displayName,
            'email': user.email,
            'emailVerified': user.emailVerified,
            'photoURL': user.photoURL,
            'isAnonymous': user.isAnonymous,
            'uid': user.uid,
            'providerData': user.providerData
          }
        }
      )
      // ...
    } else {
      // User is signed out.
      this.setState(
        {
          'user': {
            'displayName':'',
            'email':'',
            'emailVerified':'',
            'photoURL':'',
            'isAnonymous':'',
            'uid':'',
            'providerData':''
          }
        }
      )
      }
  }

  render() {

    let loginButton;
    let d = this.state.user.displayName
    if (!d) {
      d = this.state.user.email
    }
    if (this.state.user.email !== '') {
      loginButton = (
        <Link to='/account' className="nav-link">{d}</Link>
      )
    } else {
      loginButton = (
        <Link to='/login' className="nav-link">Login</Link>
      )
    }


    return (
      <div className="App">
        <Router>

          <nav className="navbar navbar-expand-sm navbar-light bg-light">
            <svg className="bi mr-2" width="32" height="32" fill="currentColor">
              <use xlinkHref="bootstrap-icons.svg#moon" />
            </svg>
            <Link to="/" className="navbar-brand">LunaScribble</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link to='/' className="nav-link">My Scribbles</Link>
                </li>                
              </ul>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to='/about' className="nav-link">About LS</Link>
                </li>
                <li className="nav-item">
                  {loginButton}
                </li>
              </ul>
            </div>
          </nav>

          <Switch>
            <Route path="/s">
              <Scribble />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>

        </Router>
      </div>
    );
  }
}

export default App;
