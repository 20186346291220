import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Link,
    withRouter
  } from "react-router-dom";
import { Card } from 'react-bootstrap'
import * as firebase from "firebase/app";
import "firebase/auth";
import LoginExistingForm from "./LoginExistingForm"

class Login extends React.Component {

    render() {
        const match = this.props.match;

        return (

            <div className="m-5">
                <Router>
                    <Switch>
                        <Route path={`${match.url}/new`}>
                            who dat
                        </Route>
                        <Route path={`${match.url}/existing`}>
                            <Card className='login-card p-4 m-4 ml-auto mr-auto'>
                                <LoginExistingForm />
                            </Card>
                        </Route>
                        <Route path={match.path}>
                            <div className=''>
                                <div className='p-2'><Link to={`${match.url}/new`}>Make an Account</Link></div>
                                <div className='p-2'><Link to={`${match.url}/existing`}>Log in to your Existing Account</Link></div>
                            </div>
                        </Route>
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default withRouter(Login);