import React from 'react';
import { Button } from 'react-bootstrap'
import * as firebase from "firebase/app";
import "firebase/auth";
import { Redirect } from 'react-router-dom';

class Account extends React.Component {

    constructor() {
        super()
        this.logUserOut = this.logUserOut.bind(this);
        this.state = {
            'redirect': ''
        }
    }

    logUserOut() {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            //alert("signed out")
        }).catch(function(error) {
            // An error happened.
            alert(error.message)
        });
        this.setState({
            'redirect': <Redirect to="/" />
        })
    }
    render() {
        return (
            <div className="m-5">
                <p>ur cool u kno?</p>
                <Button type="button" className="btn btn-dark" onClick={this.logUserOut}>Log out</Button>
                {this.state.redirect}
            </div>
        )
    }
}

export default Account;