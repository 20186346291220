import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Link,
    Redirect
  } from "react-router-dom";
import { Card } from 'react-bootstrap'
import * as firebase from "firebase/app";
import "firebase/auth";




class LoginExistingForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.state = {
            'email':'',
            'password':'',
            'redirect':''
        }
    }
    
    onChangeEmail(event) {
        this.setState(
            {
                'email': event.target.value
            }
        )
    }

    onChangePassword(event) {
        this.setState(
            {
                'password': event.target.value
            }
        )
    }
    
    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        
        //alert("logged in")
        var email = this.state['email'];
        var password = this.state['password'];
        firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            alert(errorMessage)
            // ...
        });
        this.setState(
            {
                'redirect': <Redirect to="/s" />
            }
        )
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <h5 className="m-4">Welcome Back!</h5>
                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" className="form-control" id="loginEmail" 
                            onChange={this.onChangeEmail} aria-describedby="emailHelp" placeholder="Enter email" />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" id="loginPassword" 
                            onChange={this.onChangePassword} placeholder="Enter password" />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
                {this.state.redirect}
            </div>
        )
    }
}

export default LoginExistingForm;